<template>
  <section class="resetPassword flex justifyCenter alignCenter com-bg-img">
    <LoginCard
      :title="title"
      :width="pxtorem('600px')"
      :height="pxtorem('612px')"
    >
      <div class="resetPassword-form-box">
        <el-form
          :model="resetPassForm"
          :rules="rules"
          ref="resetPassForm"
          label-width="100px"
          class="resetPassForm"
          label-position="top"
        >
          <el-form-item
            v-for="item in resetFormList"
            :key="item.prop"
            :label="item.label"
            :prop="item.prop"
          >
            <!-- 验证码 -->
            <div class="email-code" v-if="item.isCode">
              <div v-if="item.isText" class="text-item">
                {{
                  resetPrivateStr(String(resetPassForm[item.prop] || ""), 3, 3)
                }}
              </div>
              <el-input
                v-else
                v-model="resetPassForm[item.prop]"
                :placeholder="item.placeholder"
                autocomplete="new-password"
                :maxlength="item.maxlength"
              ></el-input>

              <div class="sendBtn" @click="sendEmailCode(item)">
                <Loading :loading="item.sendMessLoading">
                  <span>{{
                    item.isCount ? item.timeCount + "s" : "发送验证码"
                  }}</span>
                </Loading>
              </div>
            </div>
            <!-- 密码框 -->
            <el-input
              v-else-if="item.inputType === 'password'"
              :type="item.eyeFlag ? 'text' : 'password'"
              v-model.trim="resetPassForm[item.prop]"
              :placeholder="item.placeholder"
              autocomplete="new-password"
              :maxlength="item.maxlength"
            >
              <img
                slot="suffix"
                :src="item.eyeFlag ? eyeImg : eyeOffImg"
                class="eye-img"
                @click="item.eyeFlag = !item.eyeFlag"
              />
            </el-input>
            <!-- 输入框 -->
            <el-input
              v-else
              v-model="resetPassForm[item.prop]"
              :placeholder="item.placeholder"
              autocomplete="new-password"
              :maxlength="item.maxlength"
            ></el-input>

            <!-- 表单提示 -->
            <span v-if="item.tips" class="tips"
              ><i class="el-icon-warning-outline"></i>
              <span>{{ item.tips }}</span>
            </span>
          </el-form-item>
        </el-form>
        <div
          class="operation-user verify-confirm"
          @click="ResetPassConfirm('resetPassForm')"
        >
          <Loading :loading="isLoading" color="#fff">
            <span>确认修改</span>
          </Loading>
        </div>
        <div
          class="has-register"
          @click="isEditPass ? $router.go(-1) : $router.push({ name: 'Login' })"
        >
          <span>{{ isEditPass ? "返回上一级" : "返回登录页面" }}</span>
        </div>
      </div>
    </LoginCard>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import { pxtorem, resetPrivateStr } from "@/utils/common.js";
import LoginCard from "@/components/login/LoginCard.vue";
import Loading from "@/components/common/Loading.vue";
import { SHA256 } from "@/utils/sha256";

import {
  updatePassword,
  forgetUpdatePassword,
  sendMessUpdatePass,
  sendMessResetPass,
} from "@/api//interface/login";

export default {
  components: {
    LoginCard,
    Loading,
  },
  created() {
    this.initPage();
  },
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        let reg =
          /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)]|[\(\)])+$)([^(0-9a-zA-Z)]|[\(\)]|[a-z]|[A-Z]|[0-9]){6,18}$/;
        if (!reg.test(value)) {
          callback(
            new Error(
              this.isEditPass
                ? "请输入6到18位密码，包含数字、特殊字符、大写和小写字符(任意两种)"
                : "请输入有效密码"
            )
          );
        }
        callback();
      }
    };
    const validateCheckPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.resetPassForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    const validatePhone = (rule, value, callback) => {
      let reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
      if (!value) {
        callback(new Error("请输入手机号码"));
      } else if (reg.test(value)) {
        callback();
      } else {
        callback(new Error("请输入有效手机号码"));
      }
    };
    return {
      title: "",
      isEditPass: false,
      isLoading: false,
      eyeImg: require("@/assets/images/common/eye.png"),
      eyeOffImg: require("@/assets/images/common/eye-off.png"),
      resetFormList: [],
      resetPassForm: {
        password: "",
        confirmPassword: "",
        phone: "",
        smsCode: "",
      },
      rules: {
        password: {
          required: true,
          validator: validatePass,
          trigger: ["change", "blur"],
        },
        confirmPassword: {
          required: true,
          validator: validateCheckPass,
          trigger: ["change", "blur"],
        },
        smsCode: {
          required: true,
          message: "请输入短信验证码",
          trigger: ["change", "blur"],
        },
      },
      validatePhone,
    };
  },
  mounted() {
    // 监听回车按键
    document.onkeydown = (e) => {
      let key = e.key;
      if (key === "Enter") {
        this.ResetPassConfirm("resetPassForm");
      }
    };
  },
  computed: {
    ...mapGetters(["deviceInfo", "userInfo"]),
    forgetFormList() {
      return [
        {
          label: "手机号",
          prop: "phone",
          placeholder: "请输入手机号",
          sendMessLoading: false,
          timeCount: 60,
          isCount: false,
          isCode: true,
          maxlength: 11,
        },
        {
          label: "短信验证码",
          prop: "smsCode",
          placeholder: "请输入短信验证码",
          maxlength: 6,
        },
        {
          label: "密码",
          eyeFlag: false,
          inputType: "password",
          prop: "password",
          placeholder: "请输入新密码",
          tips: "请输入6到18位密码，包含数字、特殊字符、大写和小写字符(任意两种)",
          maxlength: 18,
        },
        {
          label: "确认密码",
          eyeFlag: false,
          inputType: "password",
          prop: "confirmPassword",
          placeholder: "请确认密码",
          maxlength: 18,
        },
      ];
    },
    EditFormList() {
      return [
        {
          label: "新密码",
          eyeFlag: false,
          inputType: "password",
          prop: "password",
          placeholder: "请输入新密码",
        },
        {
          label: "确认密码",
          eyeFlag: false,
          inputType: "password",
          prop: "confirmPassword",
          placeholder: "请确认密码",
        },
        {
          isText: true,
          label: "手机号",
          prop: "phone",
          placeholder: "请输入手机号",
          timeCount: 60,
          isCount: false,
          isCode: true,
        },
        {
          label: "短信验证码",
          prop: "smsCode",
          placeholder: "请输入短信验证码",
        },
      ];
    },
  },
  methods: {
    pxtorem,
    resetPrivateStr,
    // 短信接口
    sendMessCode(formItem) {
      formItem.sendMessLoading = true;
      const userName =
        this.userInfo.loginUser && this.userInfo.loginUser.username;
      let sendMessAsync = this.isEditPass
        ? sendMessUpdatePass({ phone: userName })
        : sendMessResetPass({ phone: this.resetPassForm.phone });
      sendMessAsync
        .then((res) => {
          this.$message.success("发送成功！");
          this.noteInfoDisabled(formItem);
        })
        .catch()
        .finally(() => {
          formItem.sendMessLoading = false;
        });
    },
    // 修改密码
    handleEditPass() {
      this.isLoading = true;
      const params = { ...this.resetPassForm };
      params.password = SHA256(params.password);
      params.confirmPassword = SHA256(params.confirmPassword);
      let updatePassAsync = this.isEditPass
        ? updatePassword(params)
        : forgetUpdatePassword(params);
      updatePassAsync
        .then((res) => {
          localStorage.clear();
          sessionStorage.clear();
          this.$message.success("密码重置成功，请重新登录！");
          this.$router.push({
            name: "Login",
          });
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
        });
    },

    // 验证码重置
    noteInfoDisabled(item) {
      item.isCount = true;
      item.timeCount -= 1;
      let timer = setInterval(() => {
        if (item.timeCount <= 0) {
          item.isCount = false;
          item.timeCount = 60;
          clearInterval(timer);
          timer = null;
        } else {
          item.timeCount -= 1;
        }
      }, 1000);
    },

    resetForm() {
      this.isAgree = false;
      this.refs.resetPassForm.resetFields();
    },
    sendEmailCode(formItem) {
      if (formItem.isCount) return;
      this.$refs.resetPassForm.validateField(formItem.prop, (valid) => {
        if (!valid) {
          this.sendMessCode(formItem);
        }
      });
    },
    ResetPassConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.handleEditPass();
        } else {
          return false;
        }
      });
    },
    // 初始化页面
    initPage() {
      const {
        query: { pass },
      } = this.$route;
      this.title = pass === "edit" ? "修改密码" : "忘记密码";
      // this.isEditPass = pass === "edit";
      this.resetFormList =
        pass === "edit" ? this.EditFormList : this.forgetFormList;
      if (pass === "edit" && this.userInfo.authorization) {
        this.resetPassForm = {
          ...this.resetPassForm,
          phone: this.userInfo.loginUser.phone,
        };

        delete this.rules["phone"];
      } else {
        this.$set(this.rules, "phone", {
          required: true,
          validator: this.validatePhone,
          trigger: ["change", "blur"],
        });
      }
    },
  },
  beforeDestroy() {
    document.onkeydown = "";
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/login/_box_card.scss";

.resetPassword {
  height: 100vh;
  width: 100%;
  .box-card {
    padding: 0.62rem 0.59rem 0.8rem;
  }
  .resetPassForm {
    margin-top: 31px;
  }
  :deep() .el-form {
    .el-form-item {
      margin-bottom: 24px;
    }
  }
}
</style>
